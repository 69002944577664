import React, { useState, useEffect } from "react";
import { fetchWithJwt } from "components/fetchWithJwt";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const Store = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [dataAccount, setDataAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({ email: "", uid: "", client_id: "", secret_key: "", expired_at: "" });
  const [selectedId, setSelectedId] = useState(null);

  const fetchAccountData = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/account`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      if (Array.isArray(data)) {
        // Format expired_at untuk setiap item agar sesuai dengan input type="date"
        const formattedData = data.map((item) => ({
          ...item,
          expired_at: item.expired_at ? formatDateForInput(item.expired_at) : "", // Jika expired_at ada, format
        }));
        setDataAccount(formattedData);
        setFilteredData(formattedData);
      } else {
        throw new Error("Data tidak valid");
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  const handleDelete = async (id) => {
    if (isLoading) return;
  
    setIsLoading(true); 
    try {
      const confirmDelete = await Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Data akan dihapus permanen!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      });
  
      if (confirmDelete.isConfirmed) {
        const response = await fetchWithJwt(`${API_DOMAIN}/account/${id}`, {
          method: "DELETE",
        });
  
        if (!response.ok) throw new Error("Gagal menghapus data!");
  
        Swal.fire("Dihapus!", "Data berhasil dihapus.", "success");
        fetchAccountData();
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      Swal.fire("Gagal!", "Terjadi kesalahan saat menghapus.", "error");
    } finally {
      setIsLoading(false); 
    }
  };
  
  const handleAddOrEditAccount = async () => {
    if (isLoading) return;
  
    setIsLoading(true);
    try {
      if (!formData.email) throw new Error("Email tidak boleh kosong");
  
      // Jika sedang dalam mode edit, kita format tanggalnya agar sesuai
      if (isEditing && formData.expired_at) {
        const formattedDate = formatDateForInput(formData.expired_at); // Memformat tanggal untuk input
        formData.expired_at = formattedDate;
      }
  
      const url = isEditing ? `${API_DOMAIN}/account/${selectedId}` : `${API_DOMAIN}/account`;
      const method = isEditing ? "PUT" : "POST";
      const requestBody = isEditing
        ? { email: formData.email, expired_at: formData.expired_at }
        : formData;
  
      const response = await fetchWithJwt(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  
      Swal.fire({
        icon: "success",
        title: `Store ${isEditing ? "diperbarui" : "ditambahkan"} dengan sukses`,
        showConfirmButton: false,
        timer: 1500,
      });
      setIsModalOpen(false);
      fetchAccountData();
      setFormData({ email: "", uid: "", client_id: "", secret_key: "", expired_at: "" });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `Store gagal ${isEditing ? "diperbarui" : "ditambahkan"}`,
        showConfirmButton: false,
        timer: 1500,
      });
      console.error("Error saving store:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const formatDateForInput = (date) => {
    const d = new Date(date); // Mengonversi string ISO 8601 ke objek Date
    const day = String(d.getDate()).padStart(2, '0'); // Menambahkan leading zero untuk tanggal
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Menambahkan leading zero untuk bulan
    const year = d.getFullYear(); // Tahun
    return `${year}-${month}-${day}`; 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const openModalForAdd = () => {
    setIsEditing(false);
    setFormData({ email: "", uid: "", client_id: "", secret_key: "", expired_at: "" });
    setIsModalOpen(true);
  };

  const openModalForEdit = (account) => {
    setIsEditing(true);
    setSelectedId(account.id);
    setFormData({
      email: account.email,
      expired_at: account.expired_at || "",
    });
    setIsModalOpen(true);
  };
  
  useEffect(() => {
    fetchAccountData();
  }, []);

  useEffect(() => {
    if (searchValue) {
      const filtered = dataAccount.filter((item) =>
        item.email.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(dataAccount); // Jika tidak ada nilai pencarian, tampilkan semua data
    }
  }, [searchValue, dataAccount]);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return new Date(dateString).toLocaleDateString("id-ID", { weekday: "long", day: "2-digit", month: "long", year: "numeric" });
  };
  
  const handleEmailChange = (e) => {
    const { value } = e.target;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    setIsEmailValid(value === "" || emailPattern.test(value)); 
    handleChange(e);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  
    return () => {
      document.body.style.overflow = "auto"; 
    };
  }, [isModalOpen]);

  return (
    <div className="p-4 bg-white">
      <div className="flex items-center gap-3 mb-4">
        <div className="relative flex-grow">
          <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-lg"/>
          <input value={searchValue} onChange={(e) => setSearchValue(e.target.value)} type="text" placeholder="Cari..." className="w-full pl-10 pr-3 py-2 border rounded-lg text-lg outline-none focus:ring-2 focus:ring-blue-400"/>
        </div>
        <button onClick={openModalForAdd} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-md flex items-center gap-2 transition-all">
          <FontAwesomeIcon icon={faPlus} className="text-md" />
          <span className="sm:block hidden text-sm">Tambah Akun</span>
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
        {filteredData.map((item, index) => {
          const expiredDate = item.expired_at ? new Date(item.expired_at) : null;
          const today = new Date();
          let statusIcon = "";
          let statusColor = "";
          if (!expiredDate) {
            statusIcon = "Perlu diperbarui";
            statusColor = "bg-yellow-100 text-yellow-800";
          } else {
            const diffDays = Math.ceil((expiredDate - today) / (1000 * 60 * 60 * 24));
          
            if (diffDays > 3) {
              statusIcon = "Aktif";
              statusColor = "bg-green-200 text-green-800";
            } else if (diffDays >= 0) {
              statusIcon = "3 HARI LAGI !";
              statusColor = "bg-orange-200 text-orange-800";
            } else {
              statusIcon = "Tidak Aktif";
              statusColor = "bg-red-200 text-red-800";
            }
          }
          return (
            <div key={index} className="relative bg-white border border-gray-200 rounded-xl shadow-md transition-all hover:shadow-lg flex flex-col">
              {/* STATUS TAG */}
              <div className={`absolute top-0 right-0 px-4 py-1 text-xs font-semibold rounded-tr-xl ${statusColor}`} style={{ borderBottomLeftRadius: "10px" }}>
                {statusIcon}
              </div>

              {/* KONTEN UTAMA */}
              <div className="p-5 flex flex-col gap-2 mt-5">
                {/* Informasi Akun */}
                <h3 className="text-sm font-semibold text-blue-800 truncate">{item.email}</h3>
                <p className="text-xs text-gray-700 font-bold">
                  <span className="font-semibold text-gray-800">Created : </span> {formatDate(item.created_at)}
                </p>
                <p className="text-xs text-gray-700 font-bold">
                  <span className="font-semibold text-gray-800">Expired : </span> {item.expired_at ? formatDate(item.expired_at) : "-"}
                </p>

                {/* ACTION BUTTONS */}
                <div className="flex justify-end gap-2 mt-2">
                  <button onClick={() => openModalForEdit(item)} className="px-4 py-1 text-xs font-semibold text-yellow-700 hover:bg-yellow-100 border border-yellow-700 rounded-md hover:bg-yellow-200 transition">
                    <FontAwesomeIcon icon={faEdit} className="mr-2" />
                    Edit
                  </button>

                  <button onClick={() => handleDelete(item.id)} className="px-4 py-1 text-xs font-semibold text-red-700 hover:bg-red-100 border border-red-700 rounded-md hover:bg-red-200 transition">
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    Hapus
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-full max-w-md p-4 rounded mx-3">
            <h2 className="text-lg font-bold mb-4">{isEditing ? "Edit Akun" : "Tambah Akun"}</h2>
            <div className="flex flex-col gap-3">
              <div>
                <label className="block text-sm font-semibold mb-1">Email</label>
                <input type="email" name="email" value={formData.email} onChange={handleEmailChange} placeholder="Email" required className={`w-full px-2 py-1 border rounded ${formData.email && !isEmailValid ? "border-red-500 bg-red-100" : "border-gray-300"}`}
              />
              {!isEmailValid && <p className="text-red-500 text-sm mt-1">Format email tidak valid</p>}
            </div>
              {!isEditing || (isEditing && new Date(formData.expired_at) - new Date() <= 3 * 24 * 60 * 60 * 1000) ? (
                <>
                  <div>
                    <label className="block text-sm font-semibold mb-1">UID</label>
                    <input type="text" name="uid" value={formData.uid} onChange={handleChange} placeholder="UID" className="w-full px-2 py-1 border rounded"/>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold mb-1">Client ID</label>
                    <input type="text" name="client_id" value={formData.client_id} onChange={handleChange} placeholder="Client ID" className="w-full px-2 py-1 border rounded"/>
                  </div>
                  <div>
                    <label className="block text-sm font-semibold mb-1">Secret Key</label>
                    <input type="text" name="secret_key" value={formData.secret_key} onChange={handleChange} placeholder="Secret Key" className="w-full px-2 py-1 border rounded"/>
                  </div>
                </>
              ) : null}
             <div> 
              <label className="block text-sm font-semibold mb-1">Expired At</label>
              <input type="date" name="expired_at" value={formData.expired_at} onChange={handleChange} className="w-full px-2 py-1 border rounded"/>
            </div>
              {/* Tombol sejajar */}
              <div className="flex justify-end gap-2">
                <button onClick={() => setIsModalOpen(false)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                  Batal
                </button>
                <button onClick={handleAddOrEditAccount} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Store;
