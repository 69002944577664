import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear,faSignOut,faBroadcastTower,faUser,faStore,faDatabase,faBell,faClipboard,faKey,} from "@fortawesome/free-solid-svg-icons";

const User = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) navigate("/auth/login");
  }, [userData, navigate]);

  const handleLogout = () => {
    Swal.fire({
      text: "Apakah Anda yakin ingin keluar?",
      showCancelButton: true,
      confirmButtonColor: "#007AFF",
      cancelButtonColor: "#FF3B30",
      confirmButtonText: "Ya, keluar",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
        sessionStorage.clear();
        
        Swal.fire({
          title: "Berhasil Logout!",
          text: "Anda telah keluar.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          navigate("/auth/login");
          window.location.reload();
        });
      }
    });
  };

  const menuClick = () => {
    Swal.fire({
      text: "Menu ini sedang dalam perbaikan",
      confirmButtonColor: "#007AFF",
      confirmButtonText: "OK",
    });
  };

  const Menu = ({ label, icon, onClick }) => (
    <>
      <button
        onClick={onClick}
        className="w-full flex items-center gap-3 py-4 px-5 text-md font-medium text-gray-800 bg-white hover:bg-blue-50 hover:text-blue-600 transition duration-200"
      >
        <FontAwesomeIcon icon={icon} className="text-lg" />
        {label}
      </button>
      <hr className="border-gray-300" />
    </>
  );

  return (
    <div className="min-h-screen w-full bg-white text-gray-900 pb-10 sm:pb-20">
      <div className="container mx-auto px-6 md:px-12 py-6">
        <div className="flex items-center gap-3 text-blue-500 mb-4">
          <FontAwesomeIcon icon={faGear} className="text-2xl hover:animate-spin" />
          <h2 className="text-xl font-semibold sm:text-2xl">Pengaturan</h2>
        </div>
        <div className="flex flex-col max-h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
          {userData.role_id === 1 && (
            <>
              <Menu onClick={() => navigate("/broadcast")} label="Broadcast" icon={faBroadcastTower} />
              <Menu onClick={() => navigate("/account")} label="Data Akun" icon={faUser} />
              <Menu onClick={() => navigate("/store")} label="Data Store" icon={faStore} />
              <Menu onClick={() => navigate("/backup")} label="Backup Database" icon={faDatabase} />
              <Menu onClick={() => navigate("/logs")} label="Log Sistem" icon={faClipboard} />
            </>
          )}
          {(userData.role_id === 1 || userData.role_id === 2) && (
            <Menu onClick={() => navigate("/notes")} label="Catatan Notifikasi" icon={faBell} />
          )}
          <Menu onClick={menuClick} label="Ganti Password" icon={faKey} />
        </div>
        <button
          onClick={handleLogout}
          className="w-full flex items-center justify-center gap-3 py-3 mt-4 text-white bg-red-500 hover:bg-red-600 transition duration-200"
        >
          <FontAwesomeIcon icon={faSignOut} className="text-lg" />
          Keluar
        </button>
      </div>
    </div>
  );
};

export default User;
