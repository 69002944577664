import React, { useState, useEffect } from "react";
import { fetchWithJwt } from "components/fetchWithJwt";

const LogViewer = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await fetchWithJwt(`${API_DOMAIN}/log`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        
        const data = await response.json();
        setLogs(data);
        setFilteredLogs(data);
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };
    
    fetchLogs();
  }, [API_DOMAIN]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    const filtered = logs.filter((log) =>
      Object.values(log).some((val) => String(val).toLowerCase().includes(value))
    );
    setFilteredLogs(filtered);
  };

  const formatTimestamp = (timestamp) => new Date(timestamp).toLocaleString();

  return (
    <div className="flex flex-col items-center text-green-400 bg-[#0a0f0d] sm:px-8 sm:pt-10 pt-6 pb-20 px-2 h-[calc(98vh-50px)]">
      <h1 className="text-2xl font-semibold text-green-300 mb-4">Monitoring Logs Viewer</h1>
      <input type="text" placeholder="Cari log..." value={searchValue} onChange={handleSearch} className="w-full p-3 mb-4 border border-green-600 rounded-lg shadow-sm bg-[#131a17] text-green-400 focus:outline-none focus:ring-2 focus:ring-green-500"/>

      {/* Kotak utama yang fleksibel */}
      <div className="w-full bg-[#131a17] rounded-lg shadow-lg p-4 border border-green-700 flex-grow overflow-hidden">
      <div className="h-full overflow-y-auto text-sm scrollbar-logs">
      {filteredLogs.length > 0 ? (
      filteredLogs.map((log, index) => (
      <div key={index} className="flex flex-row gap-4 py-2 border-b border-green-800 last:border-none text-[10px] sm:text-sm">
      <div className="w-1/4 font-mono text-green-300">{formatTimestamp(log.timestamp)}</div>
      <div className="w-3/4 font-mono text-green-400 tracking-wider">{log.message}</div>
      </div>
      ))
      ) : (
      <p className="text-center text-green-600">Tidak ada log yang ditemukan.</p>
      )}
      </div>
      </div>
</div>

  );
};

export default LogViewer;
