import React, { useState, useEffect } from "react";
import { fetchWithJwt } from "components/fetchWithJwt";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch, faClock, faEdit, faThLarge, faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';


const Store = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [dataStore, setDataStore] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [storeToEdit, setStoreToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const resetFormData = () => {setFormData({ nama: "", kontakStore: "", alamat: "", jamBuka: "", jamTutup: "", is24Hours: true, layout: [] });};
  const [formData, setFormData] = useState({ nama: "", kontakStore: "",  alamat: "", jamBuka: "", jamTutup: "", is24Hours: true, layout: [] });
 
  const handleLayoutUpload = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files).map(file => ({
      file: file,
      name: file.name,
    }));
    setFormData((prevData) => ({
      ...prevData,
      layout: [...prevData.layout, ...newFiles],
    }));
    e.target.value = null;
  };

  const fetchStoreData = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/store`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setDataStore(data);
        setFilteredData(data);
      } else {
        throw new Error("Invalid data format");
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  const createFormData = () => {
    const formDataToSend = new FormData();
    formDataToSend.append("nama", formData.nama);
    formDataToSend.append("kontakStore", formData.kontakStore);
    formDataToSend.append("alamat", formData.alamat);
    formDataToSend.append("is24Hours", formData.is24Hours);
  
    if (formData.is24Hours == 0) {
      formDataToSend.append("jamBuka", formData.jamBuka);
      formDataToSend.append("jamTutup", formData.jamTutup);
    }
  
    formData.layout.forEach(({ file, name }) => {
      formDataToSend.append("layouts", file, name);
    });
    return formDataToSend;
  };
  
  const handleEdit = (storeId) => {
    const store = dataStore.find((item) => item.id === storeId);
    if (store) {
      setStoreToEdit(store);
      const updatedFormData = {
        nama: store.nama,
        kontakStore: store.kontakStore,
        alamat: store.alamat,
        jamBuka: store.jam_buka,
        jamTutup: store.jam_tutup,
        is24Hours: store.is24Hours , 
        layout: store.layout || [],
      };
      setFormData(updatedFormData);
      setIsModalOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "kontakStore") {
      // Hanya izinkan angka dan batasi maksimal 14 digit
      const onlyNumbers = value.replace(/\D/g, "").slice(0, 14);
      setFormData((prevData) => ({
        ...prevData,
        [name]: onlyNumbers,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  
  

  const updateStore = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const formDataToSend = createFormData();
      if (formData.layout.length === 0) {
        formDataToSend.append("layout", storeToEdit.layout || []);
      }
      const response = await fetchWithJwt(`${API_DOMAIN}/store/${storeToEdit.id}`, { method: "PUT", body: formDataToSend });
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      setIsModalOpen(false);
      Swal.fire({ icon: "success", title: "Store berhasil diperbarui", showConfirmButton: false, timer: 1500 });
      fetchStoreData();
    } catch (error) {
      Swal.fire({ icon: "error", title: "Store gagal diperbarui", showConfirmButton: false, timer: 1500 });
      console.error("Error updating store:", error);
    } finally {
      setIsLoading(false);
    }
  };  

  const handleDelete = async (storeId) => {
    if (isLoading) return;
    const confirmDelete = await Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Store ini akan dihapus permanen.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Hapus",
    });
    if (confirmDelete.isConfirmed) {
      setIsLoading(true);
      try {
        const response = await fetchWithJwt(`${API_DOMAIN}/store/${storeId}`, { method: "DELETE" });
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        Swal.fire({ icon: "success", title: "Store berhasil dihapus", showConfirmButton: false, timer: 1500 });
        fetchStoreData();
      } catch (error) {
        Swal.fire({ icon: "error", title: "Store gagal dihapus", showConfirmButton: false, timer: 1500 });
        console.error("Error deleting store:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const addStore = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const formDataToSend = createFormData();
      const response = await fetchWithJwt(`${API_DOMAIN}/store`, { method: "POST", body: formDataToSend });
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      setIsModalOpen(false);
      Swal.fire({ icon: "success", title: "Store berhasil ditambahkan", showConfirmButton: false, timer: 1500 });
      fetchStoreData();
      resetFormData();
    } catch (error) {
      Swal.fire({ icon: "error", title: "Store gagal ditambahkan", showConfirmButton: false, timer: 1500 });
      console.error("Error adding store:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeLayoutFromAPI = async (layoutId) => {
    // Jika sedang memuat, jangan lakukan apa-apa
    if (isLoading) {
      return;
    }
  
    // Set loading state menjadi true untuk mencegah spam klik
    setIsLoading(true);
  
    const confirmDelete = await Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Layout ini akan dihapus permanen.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Hapus",
    });
  
    if (confirmDelete.isConfirmed) {
      try {
        // Mengirim request DELETE ke API
        const response = await fetchWithJwt(`${API_DOMAIN}/store/layout/${layoutId}`, {
          method: "DELETE",
        });
  
        // Jika response tidak berhasil
        if (!response.ok) {
          throw new Error("Gagal menghapus layout");
        }
  
        // Perbarui state layouts setelah penghapusan
        setStoreToEdit((prev) => ({
          ...prev,
          layouts: prev.layouts.filter((layout) => layout.id !== layoutId),
        }));
  
        // Tampilkan sukses
        Swal.fire({
          icon: "success",
          title: "Layout berhasil dihapus",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        // Jika terjadi error
        console.error("Terjadi kesalahan saat menghapus layout:", error);
        Swal.fire({
          icon: "error",
          title: "Layout gagal dihapus",
          showConfirmButton: false,
          timer: 1500,
        });
      } finally {
        // Reset isLoading, memastikan state kembali normal setelah proses selesai
        setIsLoading(false);
      }
    } else {
      // Jika user membatalkan penghapusan, reset isLoading
      setIsLoading(false);
    }
  };

  const removeLayoutFile = (index) => {
    setFormData((prevData) => ({ ...prevData, layout: prevData.layout.filter((_, i) => i !== index) }));
  };

  const searchHandle = (value) => {
    setSearchValue(value);
    const filtered = dataStore.filter((item) => item.nama.toLowerCase().includes(value.toLowerCase()));
    setFilteredData(filtered);
  };

  const formatTime = (time) => (time ? time.substring(0, 5) : "");

  useEffect(() => {
    fetchStoreData();
  }, []);

  const options = [
    { value: "0", label: "AC 01" },
    { value: "1", label: "AC 02" },
    { value: "2", label: "AC 03" },
    { value: "3", label: "AC 04" },
    { value: "4", label: "AC 05" },
    { value: "5", label: "AC 06" },
    { value: "6", label: "AC 07" },
    { value: "7", label: "AC 08" },
    { value: "8", label: "AC 09" },
  ];
  
  return (
    <div className="p-4 bg-white">
      <div className="flex items-center gap-4 mb-4">
        <div className="relative flex-1">
          <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          <input value={searchValue} onChange={(e) => searchHandle(e.target.value)} type="text" placeholder="Cari..." className="w-full p-2 pl-10 border rounded" />
        </div>
        <button onClick={() => { setStoreToEdit(null); resetFormData(); setIsModalOpen(true); }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded gap-2 flex items-center">
          <FontAwesomeIcon icon={faPlus} />
          <span className="hidden sm:block text-xs">Tambah Store</span>
        </button>
      </div>

      {/* CARD STORE */}
      {filteredData.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 4xl:grid-cols-4 gap-4">
          {filteredData.map((item, index) => (
            <div key={index} className="p-3 border border-blue-100 rounded-lg hover:scale-[1.03] shadow-md transition duration-200 flex flex-col justify-between">
              <div className="flex items-start justify-between space-x-2">
                <div className="items-center">
                  <p className="font-semibold text-blue-700 text-xs sm:text-sm uppercase">{item.nama}</p>
                  <div className="flex items-center space-x-1 text-gray-800 text-[12px] mt-1">
                    <FontAwesomeIcon icon={faClock} className="text-gray-800" />
                    <div className="font-semibold text-[11px]">
                      {item.is24Hours ? "24 Jam" : `${formatTime(item.jam_buka)} - ${formatTime(item.jam_tutup)}`}
                    </div>
                    <span>|</span>
                    <div onClick={() => { setShowModal(true); setStoreToEdit(item); }} className="cursor-pointer flex items-center space-x-1 text-gray-800 text-[11px] hover:text-blue-600">
                      <div className={`flex items-center space-x-1 font-semibold underline hover:text-blue-600 ${item.layouts?.length ? 'text-green-600' : 'text-red-600'}`}>
                        <FontAwesomeIcon icon={faThLarge} />
                        <span>Layout</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-1 text-gray-700 text-semibold text-xs ml-auto">
                  <p className="text-xs font-semibold">ID :</p>
                  <div className="font-bold break-words text-xs">{item.id}</div>
                </div>
              </div>

              {/* Store Address */}
              <div className="my-3 p-2 shadow-inner shadow-gray-600 rounded-lg bg-gray-100 min-h-[60px]">
                <div className="text-justify break-words text-[12px] capitalize">{item.alamat}</div>
              </div>

              {/* Edit Button */}
              <div className="mt-1 flex justify-end gap-2">
                <button onClick={() => handleEdit(item.id)} className="border border-yellow-500 hover:bg-yellow-100 font-semibold py-1 px-3 text-xs rounded-md gap-2 flex items-center transition duration-200">
                  <FontAwesomeIcon icon={faEdit} className="text-xs text-yellow-700" />
                  <span className="text-yellow-700">Edit</span>
                </button>
                <button onClick={() => handleDelete(item.id)} className="border border-red-500 hover:bg-red-100 font-semibold py-1 px-3 text-xs rounded-md gap-2 flex items-center transition duration-200">
                  <FontAwesomeIcon icon={faTrash} className="text-xs text-red-700" />
                  <span className="text-red-700">Hapus</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500 mt-4">Tidak ada data</p>
      )}

      {/* MODAL TAMBAH & EDIT */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg mx-4 overflow-y-auto max-h-[95vh] ">
            <h2 className="text-lg font-bold text-center mb-6">
              {storeToEdit ? "Edit Store" : "Tambah Store"}
            </h2>
            <div className="flex flex-col gap-2">
              {/* Input Form */}
                <div>
                  <label htmlFor="nama" className="block font-medium text-xs text-gray-800">Nama Store</label>
                  <input  type="text"  id="nama"  name="nama"  value={formData.nama}  onChange={handleChange}  placeholder="Nama Store" className="w-full px-3 py-2 text-xs border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
                <div>
              <label htmlFor="kontakStore" className="block font-medium text-xs text-gray-800">
                Kontak Store
              </label>
              <input type="text" id="kontakStore" name="kontakStore" value={formData.kontakStore} onChange={handleChange} placeholder="Masukkan Kontak Store" className="w-full px-3 py-2 text-xs border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"/>
            </div>

                
                <div className="w-full max-w-md mx-auto">
                  <label htmlFor="list_ac" className="block font-medium text-xs text-gray-800">
                    List Unit AC
                  </label>
                  <div className="relative w-full">
                    <Select id="list_ac" name="list_ac" isMulti placeholder="Pilih Unit AC" className="mt-1" options={options} pageSize={10} />
                </div>
            
                </div>
                <div>
                  <label htmlFor="alamat" className="block font-medium text-xs text-gray-800">Alamat</label>
                  <input  type="text"  id="alamat"  name="alamat"  value={formData.alamat}  onChange={handleChange}  placeholder="Alamat"  className="w-full px-3 py-2 text-xs border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
                </div>
                <div>
                  <label htmlFor="is24Hours" className="block font-medium text-xs text-gray-800">Apakah buka 24 jam?</label>
                  <select  id="is24Hours"  name="is24Hours"  value={formData.is24Hours}  onChange={handleChange}  className="w-full px-3 py-2 text-xs border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
                    <option value="1">24 jam</option>
                    <option value="0">Tidak</option>
                  </select>
                </div>


              {/* Menampilkan input waktu jika is24Hours adalah "Tidak" */}
              {formData.is24Hours == 0 && (
                <div className="flex gap-4 w-full">
                <div className="w-1/2">
                  <label htmlFor="jamBuka" className="block font-medium text-xs text-gray-800">Jam Buka</label>
                  <input   type="time" id="jamBuka" name="jamBuka" value={formData.jamBuka} onChange={handleChange} className="w-full px-3 py-2 text-xs border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
                <div className="w-1/2">
                  <label htmlFor="jamTutup" className="block font-medium text-xs text-gray-800">Jam Tutup</label>
                  <input type="time" id="jamTutup" name="jamTutup" value={formData.jamTutup} onChange={handleChange} className="w-full px-3 py-2 text-xs border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
                </div>
              </div>
              
              )}
              {/* File Upload */}
              <div>
                <label htmlFor="layoutUpload" className="block font-medium text-xs text-gray-800">Upload Gambar</label>
                <input  type="file"  id="layoutUpload" multiple accept="image/*" onChange={handleLayoutUpload} className="w-full px-3 py-2 text-xs border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
              </div>

              {/* Preview Layout */}
              <div className="mt-3">
                {storeToEdit && storeToEdit.layouts?.length > 0 ? (
                  <>
                    <label className="font-semibold block mb-2">Preview Layout:</label>
                    <div className="flex flex-wrap gap-4">
                      {/* Displaying existing layouts */}
                      {storeToEdit.layouts.map((layout, index) => (
                        <div key={index} className="relative">
                          <a href={`${API_DOMAIN}/img/layout/${layout.nama_file}`} target="_blank" rel="noopener noreferrer">
                            <img src={`${API_DOMAIN}/img/layout/${layout.nama_file}`} alt={layout.nama_file} className="w-20 h-20 object-cover rounded-md cursor-pointer" />
                          </a>
                          <button onClick={() => removeLayoutFromAPI(layout.id)} className="absolute top-[-10px] right-[-10px] bg-red-500 text-white text-xs px-2 py-1 opacity-80 rounded-xl">
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      ))}
                    </div>
                  </>
                ) : formData.layout.length === 0 ? (
                  <div className="flex items-center justify-center bg-gray-300 text-gray-700 py-4 mt-0 rounded-lg">
                    Layout belum tersedia
                  </div>
                ) : null}

                {/* Displaying newly uploaded layouts */}
                {formData.layout.length > 0 && (
                  <div className="mt-3">
                    <label className="font-semibold block mb-2">Preview Layout Baru:</label>
                    <div className="flex flex-wrap gap-4">
                      {formData.layout.map((file, index) => (
                        <div key={`new-${index}`} className="relative">
                          <a href={URL.createObjectURL(file.file)} target="_blank" rel="noopener noreferrer">
                            <img src={URL.createObjectURL(file.file)} alt={file.name} className="w-20 h-20 object-cover rounded-md cursor-pointer" />
                          </a>
                          <button onClick={() => removeLayoutFile(index)} className="absolute top-[-10px] right-[-10px] bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                            <FontAwesomeIcon icon={faTrash} className="text-xs" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* Buttons */}
              <div className="flex gap-4 mt-2">
                <button onClick={() => setIsModalOpen(false)} className="w-full bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg transition duration-200">
                  Batal
                </button>
                <button onClick={storeToEdit ? updateStore : addStore} className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-200">
                  {storeToEdit ? "Update" : "Simpan"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal Layout */}
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg mx-4 overflow-y-auto max-h-[75vh]">
            <h2 className="text-lg font-bold text-center mb-4">Layout Store</h2>

            {/* Cek apakah layout tersedia */}
            <div className="flex flex-col gap-3 overflow-auto">
              {storeToEdit.layouts && storeToEdit.layouts.length > 0 ? (
                <div className="mt-3">
                  <label className="font-semibold block mb-2">Preview Layout Baru:</label>
                  <div className="flex flex-wrap gap-4">
                    {/* Displaying existing layouts */}
                    {storeToEdit.layouts.map((layout, index) => (
                      <div key={index} className="relative">
                        <a href={`${API_DOMAIN}/img/layout/${layout.nama_file}`} target="_blank" rel="noopener noreferrer">
                          <img src={`${API_DOMAIN}/img/layout/${layout.nama_file}`} alt={layout.nama_file} className="w-20 h-20 object-cover rounded-md cursor-pointer" />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p className="text-gray-600">Belum ada layout tersedia</p>
              )}
            </div>

            {/* Tombol Tutup */}
            <div className="mt-4 flex justify-end">
              <button onClick={() => setShowModal(false)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                Tutup
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Store;