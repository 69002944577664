import React, { useState, useRef, useEffect } from 'react';
import { MdInfo, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [alert, setAlert] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const otpRefs = useRef(Array(6).fill(null));
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isOtpMode, setIsOtpMode] = useState(false);
  const [countdown, setCountdown] = useState('05:00'); 
  const [otpExpiredAt, setOtpExpiredAt] = useState(null); 
  const [canResendOtp, setCanResendOtp] = useState(false);
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || '';
  const navigate = useNavigate();

  useEffect(() => {
    const storedOtpMode = localStorage.getItem('isOtpMode');
    if (storedOtpMode === 'true') {
      setIsOtpMode(true);
      setOtpExpiredAt(localStorage.getItem('otp_expired_at')); 
    }
  }, []);

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  const handleLogin = async () => {
    try {
      if (!API_DOMAIN) throw new Error('API_DOMAIN tidak ditemukan');

      const response = await fetch(`${API_DOMAIN}/login/auth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        setAlert({ type: 'error', message: responseData.message || 'Login gagal!' });
        return;
      }

      if (responseData.message === 'Login Berhasil') {
        setIsOtpMode(true);
        setOtpExpiredAt(responseData.otp_expired_at);
        localStorage.setItem('isOtpMode', 'true');
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
        localStorage.setItem('otp_expired_at', responseData.otp_expired_at);
        setCanResendOtp(false);
        setAlert({ type: 'success', message: 'Login berhasil! Masukkan OTP.' });
      } else {
        setAlert({ type: 'error', message: 'OTP tidak diterima dari server!' });
      }
    } catch (error) {
      console.error('Error:', error);
      setAlert({ type: 'error', message: 'Terjadi kesalahan saat login!' });
    }
  };
  

  const handleOtpChange = (index, value) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        otpRefs.current[index + 1]?.focus();
      }
    }
  };

  useEffect(() => {
    if (!otpExpiredAt) return;
    
    const timer = setInterval(() => {
      const now = new Date();
      const expiryTime = new Date(otpExpiredAt);
      const diff = Math.max((expiryTime - now) / 1000, 0); 

      if (diff === 0) {
        setCanResendOtp(true);
        clearInterval(timer);
      }

      const minutes = String(Math.floor(diff / 60)).padStart(2, '0');
      const seconds = String(Math.floor(diff % 60)).padStart(2, '0');
      setCountdown(`${minutes}:${seconds}`);
    }, 1000);

    return () => clearInterval(timer);
  }, [otpExpiredAt]);

  const handleOtpDelete = (index) => {
    const newOtp = [...otp];
    newOtp[index] = ''; 
    setOtp(newOtp);
  
    if (index > 0) {
      otpRefs.current[index - 1]?.focus(); 
    }
  };

  const handleOtpSubmit = async () => {
    if (otp.join('').length !== 6) {
      setAlert({ type: 'error', message: 'OTP harus terdiri dari 6 digit!' });
      return;
    }
  
    const savedUsername = username || localStorage.getItem('username');
  
    if (!savedUsername) {
      setAlert({ type: 'error', message: 'Username tidak ditemukan!' });
      return;
    }
  
    try {
      const response = await fetch(`${API_DOMAIN}/login/verify`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ otp: otp.join(''), username: savedUsername }),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        setAlert({ type: 'error', message: responseData.message || 'Verifikasi OTP gagal!' });
        setOtp(['', '', '', '', '', '']);
        return;
      }
  
      setAlert({ type: 'success', message: 'Login sukses! Anda masuk.' });
  
      const userData = {
        user_id: responseData.user_id,
        role_id: responseData.role_id,
        name: responseData.name,
        token: responseData.token
      };
  
      localStorage.setItem('userData', JSON.stringify(userData));
      localStorage.removeItem('isOtpMode');
  
      navigate('/mobile');
    } catch (error) {
      console.error('Error:', error);
      setAlert({ type: 'error', message: 'Terjadi kesalahan saat verifikasi OTP!' });
    }
  };
  

  useEffect(() => {
    if (isOtpMode) {
      otpRefs.current[0]?.focus(); 
    }
  }, [isOtpMode]);

  useEffect(() => {
    let timer;
    if (isOtpMode && countdown > 0) {
      timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
    } else if (countdown === 0) {
      setCanResendOtp(true);
    }
    return () => clearInterval(timer);
  }, [isOtpMode, countdown]);


  const handleResendOtp = async () => {
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');

    if (!savedUsername || !savedPassword) {
      setAlert({ type: 'error', message: 'Data tidak lengkap untuk mengirim ulang OTP!' });
      return;
    }

    try {
      const response = await fetch(`${API_DOMAIN}/login/auth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: savedUsername, password: savedPassword }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        setAlert({ type: 'error', message: responseData.message || 'Gagal mengirim ulang OTP!' });
        return;
      }

      setOtpExpiredAt(responseData.otp_expired_at);
      localStorage.setItem('otp_expired_at', responseData.otp_expired_at);
      setCanResendOtp(false);
      setAlert({ type: 'success', message: 'OTP baru telah dikirim!' });
    } catch (error) {
      console.error('Error:', error);
      setAlert({ type: 'error', message: 'Terjadi kesalahan saat mengirim ulang OTP!' });
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {alert && (
        <div className={`mb-4 ${alert.type === 'success' ? 'bg-green-100' : 'bg-red-100'} border-l-4 border-${alert.type === 'success' ? 'green' : 'red'}-500 text-${alert.type === 'success' ? 'green' : 'red'}-700 rounded p-4`} role="alert">
          <MdInfo className="mr-2 inline" />
          <label>{alert.message}</label>
        </div>
      )}

      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="mt-10 text-center">
          <label className="text-2xl sm:text-3xl font-bold text-indigo-600">NICO URBAN INDONESIA</label>
          <br />
          <label className="font-bold text-indigo-600">Air System Expert</label>
        </div>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
        {isOtpMode ? (
        <div className="w-full max-w-md mx-auto">
          <label className="block text-lg font-semibold text-gray-900 text-center">
            Masukkan Kode OTP
          </label>

          {/* OTP Input Boxes */}
          <div className="flex justify-center space-x-2 sm:space-x-3 mt-4">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                ref={(el) => (otpRefs.current[index] = el)}
                onKeyDown={(e) => e.key === "Backspace" && handleOtpDelete(index)}
                className="w-12 h-12 sm:w-14 sm:h-14 text-center text-xl sm:text-2xl font-bold rounded-lg border-2 border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 transition-all duration-200"
              />
            ))}
          </div>

          {/* Countdown Timer */}
          <div className="mt-4 text-center">
            <p className="text-gray-700 text-lg">Kode OTP akan kedaluwarsa dalam</p>
            <p className="text-2xl font-semibold text-indigo-600">{countdown} detik</p>
          </div>

          {/* Submit Button */}
          <button
            onClick={handleOtpSubmit}
            className="mt-6 w-full bg-indigo-600 text-white font-medium py-3 rounded-lg shadow-md hover:bg-indigo-700 transition-all duration-200"
          >
            VERIFIKASI OTP
          </button>

          {/* Resend OTP Button */}
          {canResendOtp && (
            <button
              onClick={handleResendOtp}
              className="mt-4 w-full text-indigo-600 hover:underline text-lg font-bold"
            >
              Kirim ulang OTP
            </button>
          )}
        </div>
         ) : (
            <>
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">Username</label>
                <input
                  type="text"
                  className="block w-full rounded-md border-0 py-2 pl-2 text-gray-900 shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                <div className="mt-2 relative">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    className="block w-full rounded-md border-0 py-2 pl-2 pr-10 text-gray-900 shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button onClick={togglePasswordVisibility} className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600">
                    {passwordVisible ? <MdVisibilityOff /> : <MdVisibility />}
                  </button>
                </div>
              </div>

              <div>
                <button onClick={handleLogin} className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500">
                  SIGN IN
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;