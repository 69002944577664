  import React, { useState, useEffect } from 'react';
  import { fetchWithJwt } from "components/fetchWithJwt";
  import { PhotoProvider, PhotoView } from 'react-photo-view';
  import { MdRefresh, MdTimer, MdCheckCircle, MdCancel  } from 'react-icons/md';
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faBattery, faBoltLightning } from "@fortawesome/free-solid-svg-icons";
  import Modal from 'react-modal';
  import 'react-photo-view/dist/react-photo-view.css';
  import { renderColor, renderStatusOnline, renderTooltipText } from 'helpers/statusHelpers';
  import logo from 'assets/img/logo.jpg';
  import { toast, Toaster } from "react-hot-toast";
  import Logo from "assets/img/logo.jpg";

  const Store = ({  setSelectedId}) => {
    const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
    const [storeData, setStoreData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [layoutData, setLayoutData] = useState([]);
    const [nameStore, setNameStore ] = useState('');
    const [allAcData, setAllAcData] = useState(0);
    const [abnormalData, setAbnormalData] = useState(0);
    const [offlineData, setOfflineData] = useState(0);
    const [layout, setLayout] = useState('home');
    const [loadingStores, setLoadingStores] = useState({});
    const [successStores, setSuccessStores] = useState({});

    Modal.setAppElement('#root');

    const openModal = (layout, store) => {
      setLayoutData(layout);
      setNameStore(store);
      setIsModalOpen(true);
      document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
      setIsModalOpen(false);
      setLayoutData([]);
      document.body.style.overflow = 'auto';
    };

    const fetchData = async () => {
      try {
        const response = await fetchWithJwt(`${API_DOMAIN}/device/show`);
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status} - ${response.statusText}`);
        }
        const { data } = await response.json();
        if (!data) {
          throw new Error("No data found in response");
        }
        setStoreData(data);
        if (!searchQuery) {
          setFilteredData(data);
        }
      } catch (error) {
        console.error("Error fetching store data:", error.message);
      }
    };

    const refreshDevice = async (id_store) => {
      try {
        const response = await fetchWithJwt(`${API_DOMAIN}/device/refresh/${id_store}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        await response.json();
        return true; 
      } catch (error) {
        console.error("Error refreshing device:", error);
        return false; 
      }
    };
    
    const handleRefresh = async (store) => {
      const { id } = store;
      setLoadingStores((prev) => ({ ...prev, [id]: true }));
      setSuccessStores((prev) => ({ ...prev, [id]: false }));
      try {
        const success = await refreshDevice(id);
        if (success) {
          console.log("Success! Menampilkan toast...");
          await fetchData();
          setSuccessStores((prev) => ({ ...prev, [id]: true }));
          toast.success("Berhasil memperbarui perangkat!");
        } else {
          throw new Error("Data gagal diperbarui.");
        }
      } catch (error) {
        console.error("Gagal memperbarui:", error);
        console.log("Menampilkan toast error...");
        toast.error(error.message || "Terjadi kesalahan saat memperbarui.");
      }
      setLoadingStores((prev) => ({ ...prev, [id]: false }));
      setTimeout(() => {
        setSuccessStores((prev) => ({ ...prev, [id]: false }));
      }, 2000);
    };

    const searchHandle = (value) => {
      setSearchQuery(value);
      const filtered = storeData.filter((sensor) =>
        sensor.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    };

    const goToDevice = (deviceId) => {
      setSelectedId(deviceId);
      setLayout('detail');
    };

    const formatTime = (jam_buka, jam_tutup) => {
      if (jam_buka && jam_tutup) {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
        return `${jam_buka.split(":").slice(0, 2).join(":")} - ${jam_tutup.split(":").slice(0, 2).join(":")}`;
      } else {
        return "24 Jam";
      }
    };

    useEffect(() => {
      fetchData();
      const interval = setInterval(fetchData, 60000);
      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
      searchHandle(searchQuery);
    }, [searchQuery, storeData]);

    const countAbnormalData = () => {
      let count = 0;
      storeData.forEach((store) => {
          store.alat.forEach((alat) => {
              const vaStatus = alat.status && alat.status.find((stat) => stat.code === 'va_temperature');
              const online = alat.online;
              if (vaStatus && (vaStatus.value < 230 || vaStatus.value > 260) && online === 1) {
                  count += 1;
              }
          });
      });
      setAbnormalData(count);
    };

    const hitungOfflineData = () => {
      let count = 0;
      storeData.forEach((store) => {
        store.alat.forEach((alat) => {
          const sensorStatus = alat.online;
          const remoteStatus = alat.remote_online;
          if (sensorStatus === 0) {
            count += 1;
          } else if (remoteStatus === 0) {
            count += 1;
          }
        });
      });
      setOfflineData(count);
    };

    const hitungSemuaAC = () => {
      let count = 0;
      storeData.forEach((store) => {
        store.alat.forEach((alat) => {
          count += 1;
        });
      });
      setAllAcData(count);
    };

    useEffect(() => {
      countAbnormalData();
      hitungOfflineData();
      hitungSemuaAC();
    }, [storeData]);

    const sortedData = [...filteredData].sort((a, b) => {
      const hasOfflineA = a.alat.some(alat => !alat.online);
      const hasOfflineB = b.alat.some(alat => !alat.online);
    
      if (hasOfflineA !== hasOfflineB) {
        return hasOfflineB - hasOfflineA; 
      }
    
      const hotCountA = a.alat.filter(alat => {
        const suhu = alat.status?.find(stat => stat.code === 'va_temperature')?.value / 10;
        return suhu > 26;
      }).length;
    
      const hotCountB = b.alat.filter(alat => {
        const suhu = alat.status?.find(stat => stat.code === 'va_temperature')?.value / 10;
        return suhu > 26;
      }).length;
    
      if (hotCountA !== hotCountB) {
        return hotCountB - hotCountA; 
      }
    
      const hasNormalA = a.alat.some(alat => {
        const suhu = alat.status?.find(stat => stat.code === 'va_temperature')?.value / 10;
        return suhu >= 23 && suhu < 25;
      });
    
      const hasNormalB = b.alat.some(alat => {
        const suhu = alat.status?.find(stat => stat.code === 'va_temperature')?.value / 10;
        return suhu >= 23 && suhu < 25;
      });
    
      if (hasNormalA !== hasNormalB) {
        return hasNormalB - hasNormalA; 
      }
    
      const hasColdA = a.alat.some(alat => {
        const suhu = alat.status?.find(stat => stat.code === 'va_temperature')?.value / 10;
        return suhu < 23;
      });
    
      const hasColdB = b.alat.some(alat => {
        const suhu = alat.status?.find(stat => stat.code === 'va_temperature')?.value / 10;
        return suhu < 23;
      });
    
      if (hasColdA !== hasColdB) {
        return hasColdB - hasColdA; 
      }
      return 0;
    });

    return (
      <>
      <div className="h-screen overflow-hidden">
        <Toaster position="top-center" reverseOrder={false} limit={1} />
        <div className="flex flex-col gap-3 shadow p-4 justify-center">
          <div className='flex gap-2 items-center justify-center md:justify-start'>
            <img src={logo} alt="Logo" className="w-10" />
            <div className="text-2xl font-bold">Air System Expert</div>
          </div>
          <div className='flex flex-row gap-4 items-center'>
            <div className="w-full">
              <input value={searchQuery} onChange={(e) => searchHandle(e.target.value)} type="text" placeholder="Cari..." className="w-full px-4 py-2 rounded-full bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"/>
            </div>
          </div>
        </div>

        <div className="p-4 w-full flex flex-col gap-4 overflow-scroll scrollbar-none h-full pb-[250px] sm:pb-[150px]">
        {searchQuery === "" && (
          <div className="grid grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-2 lg:gap-2">
            {[
              { title: "Jumlah Store", value: storeData.length, unit: "Store" },
              { title: "Jumlah AC Terpasang", value: allAcData, unit: "Unit" },
              { title: "Suhu Abnormal", value: abnormalData, unit: "AC" },
              { title: "Perangkat Offline", value: offlineData, unit: "Perangkat" },
            ].map((item, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-600 to-cyan-400 rounded-lg shadow-lg p-3 sm:p-5 lg:p-4 text-white flex flex-col items-center transition-all duration-300 hover:shadow-xl">
                <div className="text-xs sm:text-base lg:text-sm font-medium text-center">{item.title}</div>
                <div className="text-xl sm:text-4xl lg:text-2xl font-extrabold">
                  {item.value} <span className="text-sm sm:text-base lg:text-md opacity-80">{item.unit}</span>
                </div>
              </div>
            ))}
          </div>
        )}

        {sortedData.length > 0 ? (
          sortedData.map((store) => (
            <div key={store.id} className="flex flex-col border border-gray-300 shadow-md rounded-xl gap-2 w-full p-2">
           <div className="flex flex-col text-center md:text-left items-center md:items-start w-full p-2">
                <div className="text-md sm:text-xl font-bold text-blue-500">{store.name}</div>
                <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-y-1 md:gap-y-2 text-xs sm:text-sm md:text-base">
                  <div className="flex flex-row items-center gap-x-1">
                    <MdTimer className="text-blue-500" />
                    <div className='font-medium'>{formatTime(store.jam_buka, store.jam_tutup)}</div>
                    <span className="font-bold"> | </span>
                    <button onClick={() => openModal(store.data_layout, store.nama_file)} className={`flex items-center font-bold ${ store.data_layout.length > 0 ? "text-green-500" : "text-red-500"} hover:text-blue-700 underline`}>
                      {store.data_layout.length > 0 ? (
                        <MdCheckCircle className="w-4 h-4 mr-1" />
                      ) : (
                        <MdCancel className="w-4 h-4 mr-1" />
                      )}
                      Layout
                    </button>
                  </div>

                  <button className={`flex items-center gap-2 px-2 h-8 w-max-2xl sm:w-auto rounded-full transition-all duration-200 border border-blue-500 sm:shadow-md  ${successStores[store.id] ? "text-blue-500" : "bg-white text-blue-500 hover:bg-blue-100"}`} onClick={() => handleRefresh(store)} disabled={loadingStores[store.id]}>
                    {loadingStores[store.id] ? (
                      <div className="animate-spin">
                        <MdRefresh className="text-lg text-gray-400" />
                      </div>
                    ) : successStores[store.id] ? (
                      <MdCheckCircle className="text-lg text-blue" />
                    ) : (
                      <MdRefresh className="text-lg" />
                    )}
                    {!loadingStores[store.id] && !successStores[store.id] && <span className="text-sm font-semibold">Perbarui</span>}
                    {loadingStores[store.id] && <span className="text-sm font-semibold">Memperbarui...</span>}
                    {successStores[store.id] && <span className="text-sm font-semibold">Berhasil!</span>}
                  </button>
                </div>
              </div>

              <div className="w-full overflow-x-auto text-sm rounded-lg sm:rounded-xl">
            <table className="w-full border">
            <thead className="text-center bg-blue-500 text-white">
            <tr>
              <th rowSpan={2} className="py-2 sm:py-1 w-28 sm:w-1/5">Nomor AC</th>
              <th rowSpan={2} className="w-28 sm:w-24">Kelembaban</th>
              <th rowSpan={2} className="w-28 md:table-cell sm:w-24">Suhu</th>
              <th colSpan={3} className="hidden md:table-cell pt-1">Broadcast</th>
            </tr>
            <tr>
              <th className="w-28 md:w-24 md:table-cell pb-0 sm:pb-1">Suhu</th>
              <th className="hidden md:table-cell w-28 md:w-24 pb-1">Fan Speed</th>
              <th className="hidden md:table-cell w-28 md:w-24 pb-1">Mode</th>
            </tr>
          </thead>

          <tbody>
            {store.alat.map((alat) => {
              const isBirthday = alat.ac_category === 2; 
              return (
                <tr key={alat.device_id} onClick={() => goToDevice(alat.device_id)} className="hover:bg-gray-200 cursor-pointer h-12 text-center">
                  <td className={`relative group ${isBirthday ? "text-yellow-800 bg-yellow-100 font-bold" : ""}`}>
                    AC{alat.name.slice(5, 7)}
                    <span className="absolute left-[55px] -top-8 w-max -translate-x-1/2 scale-0 z-50 rounded-md bg-gray-700 px-2 py-1 text-xs font-bold text-white transition-all tracking-wide group-hover:scale-100">
                      {isBirthday ? "AC Birthday🎂" : "Non-Birthday"}
                    </span>
                  </td>
                  <td>{alat.status?.find((stat) => stat.code === "va_humidity")?.value}%</td>
                  <td className={`relative group ${alat.online ? renderColor(alat.status?.find((stat) => stat.code === "va_temperature")?.value) : "bg-gray-300 text-gray-700 font-bold"}`}>
                    <div className="flex items-center justify-center gap-2">
                      <div className="inline-flex items-center justify-center w-6 h-6 rounded-full text-blue-500 bg-white shrink-0">
                        {alat.status.some(s => s.code === "battery_percentage") ? (
                          <FontAwesomeIcon icon={faBattery} className="w-3 h-3" />
                        ) : (
                          <FontAwesomeIcon icon={faBoltLightning} className="w-3 h-3" />
                        )}
                      </div>
                      <span className="font-medium whitespace-nowrap text-center min-w-[32px]">
                        {alat.status?.find((stat) => stat.code === "va_temperature")?.value / 10}°
                      </span>
                    </div>
                    <span className="absolute left-1/2 -top-8 w-max -translate-x-1/2 scale-0 rounded-md bg-gray-700 px-2 py-1 text-xs font-bold text-white transition-all tracking-wide group-hover:scale-100">
                      {renderTooltipText(alat.online, alat.status?.find((stat) => stat.code === "va_temperature")?.value)}
                    </span>
                  </td>
                  <td className={renderStatusOnline(alat.remote_online)}>
                  {(store.data_broadcast?.value ?? "-") + (store.data_broadcast?.value !== null && store.data_broadcast?.value !== undefined ? "°" : "")}
                  </td>
                  <td className={`hidden md:table-cell ${renderStatusOnline(alat.remote_online)}`}>
                    Middle
                  </td>
                  <td className={`hidden md:table-cell ${renderStatusOnline(alat.remote_online)}`}>
                    Cold
                  </td>
                </tr>
              );
            })}
          </tbody>
            </table>
          </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center mt-10">
              <img src={Logo} alt="Data Tidak Ditemukan" className="w-40 h-40 opacity-30 animate-pulse" draggable="false" />
              <p className="text-gray-500 text-lg font-semibold mt-4">Data tidak ditemukan</p>
          </div>
        )}
        </div>

        {/* Modal Component */}
        {isModalOpen && (
          <PhotoProvider>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded p-4 max-w-2xl w-full overflow-y-auto m-4">
                <h2 className="text-lg font-bold mb-4">{nameStore}</h2>
                <p className="text-sm mb-4 text-black-600">Klik pada gambar untuk memperbesar gambar</p>
                <div className='flex flex-wrap gap-2'>
                  {layoutData.map((layout, index) => (
                    <div>
                    <PhotoView key={index} src={`${API_DOMAIN}/img/layout/${layout.nama_file}`}>
                      <img src={`${API_DOMAIN}/img/layout/${layout.nama_file}`} alt={layout.nama_file} className="h-20 w-20 hover:scale-110 cursor-pointer shadow-md rounded-lg"/>
                    </PhotoView>
                    <p className='text-sm text-center'>{index + 1}</p>
                    </div>
                  ))}
                </div>
                <div className="flex justify-end">
                  <button onClick={closeModal} className="px-4 py-2 bg-red-500 hover:bg-opacity-80 text-white rounded mt-4">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </PhotoProvider>
        )}
      </div>
      </>
     
    );
  };

export default Store;