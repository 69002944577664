import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "views/login";
import Mobile from "layouts/Mobile";
import Device from "views/deviceShow/detail";
import Broadcast from "views/broadcast";
import NotFound from "views/notfound";
import Store from "views/store";
import Notes from "views/notes";
import Backup from "views/backup";
import LayoutWithHeader from "layouts/LayoutWithHeader";
import Account from "views/account";
import Logs from "views/log";
import Swal from "sweetalert2";
import Test from "views/broadcast-table";

const useAuth = () => {
  const userData = localStorage.getItem("userData");
  if (!userData) return null;

  return JSON.parse(userData);
};

const PrivateRoute = ({ children, allowedRoles }) => {
  const navigate = useNavigate();
  const userData = useAuth();

  useEffect(() => {
    if (!userData) {
      navigate("/auth/login", { replace: true });
    } else if (!allowedRoles.includes(userData.role_id)) {
      Swal.fire("Akses Ditolak", "Anda tidak memiliki akses ke halaman ini", "error");
      navigate("/", { replace: true });
    }
  }, [userData, allowedRoles, navigate]);

  if (!userData || !allowedRoles.includes(userData.role_id)) {
    return null; // Render kosong selama validasi
  }

  return children;
};


const App = () => {
  return (
    <Routes>
      {/* Rute Publik */}
      <Route path="auth/login" element={ localStorage.getItem("userData") ? (
      <Navigate to="/mobile" replace />
    ) : (
        <Login />
      )
    }
  />


      {/* Rute Privat */}
      <Route
        path="mobile"
        element={
          <PrivateRoute allowedRoles={[1, 2]}>
            <Mobile />
          </PrivateRoute>
        }
      />
      <Route
        path="device/:id"
        element={
          <PrivateRoute allowedRoles={[1]}>
            <Device />
          </PrivateRoute>
        }
      />
      <Route
        path="store"
        element={
          <PrivateRoute allowedRoles={[1]}>
            <LayoutWithHeader title="Store">
              <Store />
            </LayoutWithHeader>
          </PrivateRoute>
        }
      />
      <Route
        path="notes"
        element={
          <PrivateRoute allowedRoles={[1,2]}>
            <LayoutWithHeader title="Catatan Notifikasi">
              <Notes />
            </LayoutWithHeader>
          </PrivateRoute>
        }
      />
      <Route
        path="account"
        element={
          <PrivateRoute allowedRoles={[1]}>
            <LayoutWithHeader title="Akun">
              <Account />
            </LayoutWithHeader>
          </PrivateRoute>
        }
      />
      <Route
        path="logs"
        element={
          <PrivateRoute allowedRoles={[1]}>
            <LayoutWithHeader title="Logs">
              <Logs />
            </LayoutWithHeader>
          </PrivateRoute>
        }
      />
      <Route
        path="broadcast"
        element={
          <PrivateRoute allowedRoles={[1]}>
            <LayoutWithHeader title="Broadcast">
              <Broadcast />
            </LayoutWithHeader>
          </PrivateRoute>
        }
      />
      <Route
        path="backup"
        element={
          <PrivateRoute allowedRoles={[1]}>
            <LayoutWithHeader title="Database Backup">
              <Backup />
            </LayoutWithHeader>
          </PrivateRoute>
        }
      />

      {/* Redirect Default */}
      <Route path="/" element={<Navigate to="/mobile" replace />} />

      {/* Fallback untuk Rute Tidak Dikenal */}
      <Route path="*" element={<NotFound />} />

      {/* Route untuk test */}
      <Route path="test" element={<Test />} />
    </Routes>
  );
};

export default App;
