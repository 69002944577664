import React, { useState } from "react";


const Calendar = () => {

  const hourArray = Array.from({ length: 24 }, (_, i) => i);
  const events = [
    { hour: "8", event: "Meeting" },
    { hour: "10", event: "Valentine's Day" },
    { hour: "15", event: "Project Deadline" },
  ];

  return (
    <div className="">
      <table>
        {hourArray.map((hour) => {
          const event = events.find((e) => e.hour == hour);
          return (
            <tr>
              <td key={hour}>{hour+" : 00"}</td>
              <td>{event && event.event}</td>
            </tr>
            )
        })}
      </table>
    </div>
  );
};

export default Calendar;
