import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  const [bubbles, setBubbles] = useState([]);

  useEffect(() => {
    const newBubbles = Array.from({ length: 20 }).map(() => ({
      size: Math.random() * 100 + 30,
      top: Math.random() * 100,
      left: Math.random() * 100,
      duration: Math.random() * 5 + 3,
    }));
    setBubbles(newBubbles);
  }, []);

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white overflow-hidden">
      {/* Bubble Animation */}
      <div className="absolute inset-0 overflow-hidden">
        {bubbles.map((bubble, i) => (
          <div
            key={i}
            className="absolute bg-blue-500 rounded-full opacity-30 animate-bubble"
            style={{
              width: `${bubble.size}px`,
              height: `${bubble.size}px`,
              top: `${bubble.top}%`,
              left: `${bubble.left}%`,
              animationDuration: `${bubble.duration}s`,
            }}
          />
        ))}
      </div>

      {/* Content */}
      <div className="text-center z-10 animate-fade-in">
        <h1 className="text-8xl font-extrabold text-blue-500 drop-shadow-lg">404</h1>
        <p className="text-lg font-medium mt-4">Oops! Halaman tidak ditemukan.</p>
        <p className="mt-2 text-gray-400">Mungkin URL salah atau halaman telah dihapus.</p>
        <div className="mt-6">
          <Link to="/" className="px-6 py-3 bg-white text-blue-600 font-bold text-sm hover:text-white rounded-lg shadow-lg hover:bg-blue-600 transition-transform transform hover:scale-110 active:scale-95">
            Kembali ke Beranda
          </Link>
        </div>
      </div>

      {/* Tailwind Custom Animations */}
      <style>{`
        @keyframes bubble {
          0% { transform: translateY(0) scale(1); opacity: 0.3; }
          50% { transform: translateY(-50px) scale(1.1); opacity: 0.5; }
          100% { transform: translateY(-100px) scale(1.2); opacity: 0; }
        }
        .animate-bubble {
          animation: bubble infinite alternate ease-in-out;
        }
        @keyframes fade-in {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .animate-fade-in {
          animation: fade-in 0.5s ease-out;
        }
      `}</style>
    </div>
  );
};

export default NotFound;