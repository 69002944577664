  import React, { useState, useEffect, useCallback  } from "react";
  import { fetchWithJwt } from "components/fetchWithJwt";
  import Swal from "sweetalert2";
  import { RenderActive } from "helpers/statusHelpers";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faTrash, faEdit, faPlus,faTemperatureHalf, faTablet } from "@fortawesome/free-solid-svg-icons";
  import Select from 'react-select';
  import Modal from "react-modal";

  const Broadcast = () => {
    const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
    const [data, setData] = useState([]);
    const [dataStore, setDataStore] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 
    const [storeSearch, setStoreSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState({store : "", status : "", code : ""}); 
    const [selectedStoreName, setSelectedStoreName] = useState("");
    // const [selectedDays, setSelectedDays] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [acOptions, setAcOptions] = useState([]);
    const [formData, setFormData] = useState({ id : "", id_store: "", list_ac: [], list_day: [], jam_mulai: "", jam_selesai: "", code: "T", value: "", status: true});
    const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    const dayOptions = days.map((day, index) => ({ value: index, label: day }));

    const fetchData = useCallback(async () => {
      try {
        const response = await fetchWithJwt(`${API_DOMAIN}/broadcast`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setData(data.data_broadcast);
        setDataStore(data.data_store);
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    }, [API_DOMAIN]);
    
    const handleSubmit = async () => {
      try {
        setIsLoading(true); 
    
        const { id, id_store, list_ac, list_day, jam_mulai, jam_selesai, code, value, status } = formData;
        if (!id_store || list_day.length === 0 || !jam_mulai || !jam_selesai || !code || !value || !status) {
          throw new Error("Data tidak lengkap. Pastikan semua field terisi.");
        }
        const listDayString = list_day.map((day) => day.value).join(",");
        const listAcString = list_ac.map((ac) => ac.value).join(",");
        const url = id 
          ? `${API_DOMAIN}/broadcast/${id}` 
          : `${API_DOMAIN}/broadcast`;
        const method = id ? "PUT" : "POST";
        const fetchOptions = {
          method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id, id_store, day_list: listDayString, ac_list: listAcString, jam_mulai, jam_selesai, code, value, status }),
        };
        const response = await fetchWithJwt(url, fetchOptions);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || `HTTP error! Status: ${response.status}`);
        }
        Swal.fire({
          icon: "success",
          title: id ? "Broadcast berhasil diperbarui" : "Broadcast berhasil ditambahkan",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchData();
        setFormData({
          id: "",
          id_store: "",
          list_ac: [],
          list_day: [],
          jam_mulai: "",
          jam_selesai: "",
          code: "T",
          value: "",
          status: true,
        });
    
        setIsModalOpen(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Broadcast gagal",
          text: error.message,
          showConfirmButton: false,
          timer: 1500,
        });
        console.error("Error in handleSubmit:", error);
      } finally {
        setIsLoading(false);
      }
    };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "code" && value === "PowerOff" ? { value: "PowerOff" } : {}),
    }));
    if (name === "id_store") {
      const selectedStore = dataStore.find((store) => store.id === Number(value));
      setAcOptions(selectedStore ? selectedStore.ac.split(",").map((ac) => ({ value: ac, label: "AC" + ac })) : []);
    }
  };

    const handleChangeDays = (selectedOptions) => {
      setFormData({
        ...formData,
        list_day: selectedOptions,
      });
    };

    const handleChangeAc = (selectedOptions) => {
      setFormData((prev) => ({
        ...formData,
        list_ac: selectedOptions,
      }));
    };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Apakah anda yakin?',
        text: "Broadcast akan dihapus!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal'
      });

      if (!result.isConfirmed) {
        return;
      }
      const response = await fetchWithJwt(`${API_DOMAIN}/broadcast/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      await Swal.fire({
        icon: "success",
        title: "Broadcast berhasil dihapus",
        showConfirmButton: false,
        timer: 1500,
      });

      fetchData();
    } catch (error) {
      console.error("Error deleting broadcast:", error);

      Swal.fire({
        icon: "error",
        title: "Broadcast gagal dihapus",
        text: error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleEdit = (broadcast) => {
    try {
      const store = dataStore.find((store) => store.id === broadcast.id_store);
      const storeAcList = store ? store.ac.split(",") : []; 
      const broadcastAcList = broadcast.ac_list ? broadcast.ac_list.split(",") : [];
      let acOptions = storeAcList.map((ac) => ({
        value: ac,
        label: "AC" + ac,
      }));
      let selectedAc = broadcastAcList.length > 0 
        ? broadcastAcList.map((ac) => ({
            value: ac,
            label: "AC" + ac,
          }))
        : [];
  
      console.log("Store AC List:", storeAcList);
      console.log("Broadcast AC List:", broadcastAcList);
      console.log("Final AC Options:", acOptions);
  
      setFormData({
        id: broadcast.id,
        id_store: broadcast.id_store,
        list_day: broadcast.day_list
          ? broadcast.day_list.split(",").map((day) => ({
              value: parseInt(day),
              label: days[parseInt(day)],
            }))
          : [],
        list_ac: selectedAc, 
        jam_mulai: broadcast.jam_mulai,
        jam_selesai: broadcast.jam_selesai,
        code: broadcast.code,
        value: broadcast.value,
        status: broadcast.status,
      });
      setAcOptions(acOptions);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error parsing broadcast data:", error);
    }
  };
  
  const handleAdd = () => {
    setFormData({ 
      id: "", 
      id_store: "", 
      list_ac: [], 
      list_day: [], 
      jam_mulai: "", 
      jam_selesai: "", 
      code: "T", 
      value: "", 
      status: 1
    });

    setAcOptions([]); 
    setIsModalOpen(true);
  };
  
 
  useEffect(() => {
    if (!data || data.length === 0) return;
  
    const filtered = data.filter(
      (item) =>
        (!searchValue.store ||
          (typeof item.nama_store === "string" &&
            item.nama_store.toLowerCase().includes(searchValue.store.toLowerCase()))) &&
        (!searchValue.status ||
          String(item.status) === String(searchValue.status)) &&
        (!searchValue.code || String(item.code) === String(searchValue.code))
    );
  
    setFilteredData(filtered);
  }, [searchValue, data, setFilteredData]);
  


    useEffect(() => {
      fetchData();
    }, []);

    useEffect(() => {
      if (isModalOpen) {
        document.body.style.overflow = "hidden"; 
      } else {
        document.body.style.overflow = "auto"; 
      }
      return () => {
        document.body.style.overflow = "auto"; 
      };
    }, [isModalOpen]);
    

    const formatTime = (time) => {
      return time ? time.substring(0, 5) : "";
    };

    const handleSearchChange = useCallback(
      (e) => {
        const { name, value } = e.target;
        setSearchValue((prev) => ({ ...prev, [name]: value }));
        if (name === "store") {
          setSelectedStoreName(value); // Perbarui tampilan input
        }
      },
      [setSearchValue, setSelectedStoreName]
    );
    
    const filteredStores = Array.isArray(dataStore)
  ? dataStore.filter(
      (item) =>
        item &&
        typeof item.nama === "string" &&
        typeof selectedStoreName === "string" &&
        item.nama.toLowerCase().includes(selectedStoreName.toLowerCase())
    )
  : [];


  

  return (
    <div className="p-4 sm:p-8 bg-white rounded">
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4 md:flex md:justify-between md:items-center mb-6">

        {/* Store */}
        <div className="w-full relative">
    <label className="block text-sm font-medium text-gray-800 mb-1">Store</label>
    <input
      type="text"
      name="store"
      value={selectedStoreName}
      onChange={handleSearchChange}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setTimeout(() => setIsOpen(false), 200)}
      className="w-full p-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
      placeholder="Cari atau pilih store..."
    />
    {isOpen && filteredStores.length > 0 && (
      <ul className="absolute w-full bg-white border rounded-lg shadow-lg mt-1 max-h-60 overflow-y-auto z-50">
        {filteredStores.map((item) => (
          <li
            key={item.id}
            onMouseDown={(e) => e.preventDefault()} // Mencegah blur saat memilih
            onClick={() => {
              setSearchValue((prev) => ({ ...prev, store: item.nama }));
              setSelectedStoreName(item.nama);
              setIsOpen(false);
            }}
            className="p-2 text-xs sm:text-xs cursor-pointer hover:bg-blue-100"
          >
            {item.nama}
          </li>
        ))}
      </ul>
    )}
  </div>


        {/* Status */}
        <div className="w-full">
          <label className="block text-sm font-medium text-gray-800 mb-1">Status</label>
          <select value={searchValue.status} onChange={handleSearchChange} name="status" className="w-full p-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 transition-all">
            <option value="">Semua</option>
            <option value="1">Aktif</option>
            <option value="0">Nonaktif</option>
          </select>
        </div>

        {/* Perintah */}
        <div className="w-full">
          <label className="block text-sm font-medium text-gray-800 mb-1">Perintah</label>
          <select value={searchValue.code} onChange={handleSearchChange} name="code" className="w-full p-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 transition-all">
            <option value="">Semua</option>
            <option value="T">Set Temperatur</option>
            <option value="PowerOff">Power Off</option>
          </select>
        </div>

        {/* Button Tambah - Sejajar dengan Select di Mobile, Kanan Pojok di Desktop */}
        <div className="w-full flex justify-center md:justify-end md:ml-auto items-end mt-6 sm:mt-6">
          <button onClick={() => handleAdd()} className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition-all duration-300 flex items-center gap-2 w-full md:w-auto">
            <FontAwesomeIcon icon={faPlus} />
            <span>Tambah</span>
            <span className="hidden sm:inline">Broadcast</span>
          </button>
        </div>
      </div>
    </div>

      {/* Display broadcast list */}
      {filteredData.length > 0 ? (
        <>
          {/* Header */}
          <div className="py-2 px-4 text-md hidden md:flex bg-cyan-500 flex-col md:flex-row justify-between border border-gray-200 rounded-lg shadow-sm">
            <div className="w-full md:w-64 font-semibold text-white">Gerai</div>
            <div className="w-full md:w-48 font-semibold text-white">Hari</div>
            <div className="w-full md:w-24 font-semibold text-white">Jam</div>
            <div className="w-full md:w-40 font-semibold text-white">Perintah</div>
            <div className="w-full md:w-1/4 font-semibold text-white">List Unit AC</div>
            <div className="w-full md:w-24 font-semibold text-white">Status</div>
            <div className="w-full md:w-24 font-semibold text-white">Menu</div>
          </div>

          {filteredData.length > 0 &&
            filteredData.map((item, index) => {
              const store = dataStore.find((s) => s.id === item.id_store);
              const acStoreList = store?.ac ? store.ac.split(",") : []; 
              const broadcast = data.find((b) => b.id === item.id);
              const broadcastAcList = broadcast?.ac_list ? broadcast.ac_list.split(",") : []; 
            const activeDays = item.day_list ? item.day_list.split(",") : [];
            const allDays = ["0", "1", "2", "3", "4", "5", "6"];
            return (
              <div key={index} className="py-2 px-4 bg-white border border-gray-200 rounded-lg shadow-lg hover:bg-gray-50 transition-all duration-200 gap-2 sm:mb-0 mb-5">
                {/* Mobile View */}
                <div className="md:hidden flex flex-col gap-2 sm:px-6 sm:py-6 py-2">
                  <div className="flex justify-between items-center">
                    <span className="text-cyan-600 font-semibold">{item.nama_store}</span>
                    {item.status === 1 ? (
                      <span className="px-2 py-1 text-xs font-bold text-white bg-green-500 rounded-lg">Aktif</span>
                    ) : (
                      <span className="px-2 py-1 text-xs font-bold text-white bg-red-500 rounded-lg">Nonaktif</span>
                    )}
                  </div>

                  <div className="flex flex-wrap text-gray-800 font-bold space-x-2">
                    <span>{formatTime(item.jam_mulai)}</span>
                    <span>•</span>
                    <span className={item.code === "T" ? "text-blue-500 font-bold" : "text-red-500 font-bold"}>
                      {item.code === "T" ? (
                        <>
                          <FontAwesomeIcon icon={faTemperatureHalf} className="mr-1 text-blue-500" /> 
                          {item.value}°C
                        </>
                      ) : item.code}
                    </span>
                  </div>
                  
                  <div key={index}>
                  <div className="flex flex-wrap gap-1">
                    {acStoreList.map((ac) => {
                      const isActive = broadcastAcList.includes(ac); // Cek apakah AC termasuk yang dipilih

                      return (
                        <div key={ac} className="relative group">
                          {/* Kotak angka */}
                          <span
                            className={`min-w-[24px] min-h-[28px] flex items-center justify-center rounded-sm text-xs font-bold
                              ${isActive ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-500"}`}
                          >
                            {ac}
                          </span>

                          {/* Tooltip */}
                          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 
                              text-xs text-white bg-gray-700 rounded shadow-lg opacity-0 
                              group-hover:opacity-100 transition-opacity duration-200 ease-in-out 
                              whitespace-nowrap">
                            AC {ac}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                  <div className="w-48 flex flex-wrap gap-1">
                    {allDays.map((day, index) => (
                      <div key={index} className="relative group">
                        <div className={`w-6 h-6 flex items-center justify-center rounded-full text-white text-xs font-semibold ${activeDays.includes(day) ? "bg-blue-500" : "bg-gray-300"}`}>
                          {days[day].charAt(0).toUpperCase()}
                        </div>
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1  px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg z-50 opacity-0  group-hover:opacity-100 transition-opacity duration-200 ease-in-out pointer-events-none  min-w-max whitespace-nowrap">
                          {days[day]}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex gap-3 mt-2 justify-end">
                    <button className="flex items-center gap-2 px-3 py-2 text-xs bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 hover:scale-105 transition-all duration-200" onClick={() => handleEdit(item)}>
                      <FontAwesomeIcon className="text-white" icon={faEdit} />
                      <span>Edit</span>
                    </button>
                    <button className="flex items-center gap-2 px-3 py-2 text-xs bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 hover:scale-105 transition-all duration-200" onClick={() => handleDelete(item.id)}>
                      <FontAwesomeIcon className="text-white" icon={faTrash} />
                      <span>Hapus</span>
                    </button>
                  </div>
                </div>

                {/* Desktop View (Tetap Sama) */}
                <div className="hidden md:flex justify-between w-full text-sm">
                  <div className="w-64 text-cyan-600 font-semibold">{item.nama_store}</div>
                  <div className="w-48 flex flex-wrap gap-1">
                    {allDays.map((day, index) => (
                      <div key={index} className="relative group">
                        <div className={`w-6 h-6 flex items-center justify-center rounded-full text-white text-xs font-semibold ${ activeDays.includes(day) ? "bg-blue-500" : "bg-gray-300" }`}>
                          {days[day].charAt(0).toUpperCase()}
                        </div>
                        {/* Tooltip */}
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg z-50 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out pointer-events-none min-w-max whitespace-nowrap">
                          {days[day]}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="w-24 text-gray-800 font-bold">{formatTime(item.jam_mulai)}</div>
                  <div className="w-40 text-gray-800 font-bold">{item.code === "T" ? `Set Temperatur ${item.value}°C` : item.code}</div>
                  <div className="w-1/4 whitespace-nowrap flex gap-1">
                  {acStoreList.map((ac) => {
                  const isActive = broadcastAcList.includes(ac);
                      return (
                        <div key={ac} className="relative group">
                          <span
                            className={`min-w-[24px] min-h-[28px] flex items-center justify-center rounded-sm text-xs font-bold
                              ${isActive ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-500"}`}
                          >
                            {ac}
                          </span>

                          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 
                              text-xs text-white bg-gray-700 rounded shadow-lg opacity-0 
                              group-hover:opacity-100 transition-opacity duration-200 ease-in-out 
                              whitespace-nowrap">
                            AC {ac}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="w-24 flex items-center">
                    {item.status === 1 ? (
                      <span className="px-2 py-1 text-sm font-bold text-white bg-green-500 rounded-lg">Aktif</span>
                    ) : (
                      <span className="px-2 py-1 text-sm font-bold text-white bg-red-500 rounded-lg">Nonaktif</span>
                    )}
                  </div>
                  <div className="w-24 flex gap-4">
                    <button className="hover:scale-110 transition-transform" onClick={() => handleEdit(item)}>
                      <FontAwesomeIcon className="text-blue-500 text-lg" icon={faEdit} />
                    </button>
                    <button className="hover:scale-110 transition-transform" onClick={() => handleDelete(item.id)}>
                      <FontAwesomeIcon className="text-red-500 text-lg" icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="text-center text-gray-500 py-4">Tidak ada data</div>
      )}
      
      {/* Modal Add Broadcast */}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} className="fixed inset-0 flex items-start justify-center p-4 bg-black bg-opacity-50 overflow-y-auto">
          <div className="bg-white w-full max-w-lg md:max-w-xl lg:max-w-2xl p-6 rounded-lg shadow-lg overflow-auto" style={{ marginTop: "10vh" }}>
            <h2 className="text-xl font-semibold mb-4">Tambah Broadcast</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-800">Store</label>
                <select name="id_store" value={formData.id_store} onChange={handleChange} className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 transition" disabled={formData.id}>
                  <option value="">Pilih Store</option>
                  {dataStore.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.nama}
                    </option>
                  ))}
                </select>
              </div>

              {/* AC */}
              <div>
                <label className="block text-sm font-medium text-gray-800">AC</label>
                <Select name="ac" value={formData.list_ac} onChange={handleChangeAc} options={acOptions} isMulti className="w-full"/>
              </div>

              {/* Hari */}
              <div>
                <label className="block text-sm font-medium text-gray-800">Hari</label>
                <Select name="days" value={formData.list_day} onChange={handleChangeDays} options={dayOptions} isMulti className="w-full"/>
              </div>

              {/* Jam Mulai & Jam Selesai */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-800">Jam Mulai</label>
                  <input type="time" name="jam_mulai" value={formData.jam_mulai} onChange={handleChange} className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 transition"/>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-800">Jam Selesai</label>
                  <input type="time" name="jam_selesai" value={formData.jam_selesai} onChange={handleChange} className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 transition"/>
                </div>
              </div>

              {/* Perintah & Value */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-800">Perintah</label>
                  <select name="code" value={formData.code} onChange={handleChange} className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 transition">
                    <option value="T">Set Temperatur</option>
                    <option value="PowerOff">Power Off</option>
                  </select>
                </div>

                {formData.code === "T" && (
                  <div>
                    <label className="block text-sm font-medium text-gray-800">
                      Value <span className="ml-2 text-xs">(18 - 30)</span>
                    </label>
                    <input type="number" name="value" min="18" max="30" value={formData.value} onChange={handleChange} className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 transition"/>
                  </div>
                )}
              </div>

              {/* Status */}
              <div>
                <label className="block text-sm font-medium text-gray-800">Status</label>
                <select name="status" value={formData.status} onChange={handleChange} className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 transition">
                  <option value="1">Aktif</option>
                  <option value="0">Tidak Aktif</option>
                </select>
              </div>

              {/* Buttons */}
              <div className="flex justify-end gap-4 mt-4">
                <button onClick={() => setIsModalOpen(false)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg transition">
                  Batal
                </button>
                <button onClick={handleSubmit} disabled={isLoading} className={`font-bold py-2 px-4 rounded-lg transition ${isLoading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-700 text-white"}`}>
                  {isLoading ? "Loading..." : "Simpan"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Broadcast;