import Card from 'components/card';
import html2canvas from 'html2canvas';
import { fetchWithJwt } from 'components/fetchWithJwt';
import React, { useState, useEffect, useCallback } from 'react';
import { AreaChart, Area, Tooltip, CartesianGrid, XAxis, Label, ResponsiveContainer } from 'recharts';
import { MdWaterDrop, MdThermostat, MdArrowCircleLeft, MdArrowCircleRight, MdArrowBack } from 'react-icons/md';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBattery, faBoltLightning } from "@fortawesome/free-solid-svg-icons";
import Remote from '../deviceControl/deviceControlRemote';
import BroadcastSection from './broadcast';
import logo from 'assets/img/logo.jpg';


const Detail = ({ selectedDeviceId, onClose }) => {
  const [error, setError] = useState(null);
  const [device, setDevice] = useState([]);
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState('hourly');
  const [sensorData, setSensorData] = useState([]);
  const [notifStatus, setNotifStatus] = useState(null);
  const [broadcastStatus, setBroadcastStatus] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const userRoleId = parsedUserData.role_id;
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const isToday = currentDate === new Date().toISOString().split('T')[0];
  const [acCategory, setAcCategory] = useState(device?.ac_category ?? 1);

  const handleNextDay = () => {
    const date = new Date(currentDate);
    if (period === 'daily') {
      date.setMonth(date.getMonth() + 1);
    } else if (period === 'hourly') {
      date.setDate(date.getDate() + 1);
    }
    setCurrentDate(date.toISOString().split('T')[0]);
  };
  
  const handlePreviousDay = () => {
    const date = new Date(currentDate);
    if (period === 'daily') {
      date.setMonth(date.getMonth() - 1);
    } else if (period === 'hourly') {
      date.setDate(date.getDate() - 1);
    }
    setCurrentDate(date.toISOString().split('T')[0]);
  };

  const downloadData = () => {
    const chartContainer = document.querySelector('.chart-container');
    if (chartContainer) {
      html2canvas(chartContainer).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'sensor_data_chart.png';
        link.click();
      }).catch(error => {
        console.error('Error generating image:', error);
      });
    } else {
      console.error('Chart container not found.');
    }
  };

  const processData = useCallback((data) => {
    return data.map(sensor => {
      const formattedTime = period === 'daily' ? new Date(sensor.tanggal).toLocaleDateString('id-ID', {day: '2-digit', month: '2-digit'}) : sensor.jam;
      return {
        time: formattedTime,
        temperature: (sensor.temperature / 10).toFixed(1),
        humidity: (sensor.humidity).toFixed(1),
        name: sensor.name
      };
    });
  }, [period]);

  const fetchDeviceDetail = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/detail/${selectedDeviceId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const { data_alat } = await response.json();
      setDevice(data_alat);
      setNotifStatus(data_alat.notif);
      setBroadcastStatus(data_alat.broadcast);
    } catch (error) {
      setError('Failed to fetch device details');
    }
  };

  useEffect(() => {
    setAcCategory(device?.ac_category ?? 1); 
}, [device?.ac_category]);

  const updateAcCategory = async (newValue) => {
    try {
        const deviceId = device?.id || selectedDeviceId;
        if (!deviceId) {
            console.error("Device ID tidak ditemukan!");
            throw new Error("Device ID tidak ditemukan");
        }
        console.log(`Mengupdate kategori AC untuk Device ID: ${deviceId} dengan nilai: ${newValue}`);
        const response = await fetchWithJwt(`${API_DOMAIN}/device/${deviceId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ac_category: newValue,
                device_id: deviceId 
            }),
        });
        if (!response.ok) {
            const errorText = await response.text();
            console.error(`Gagal memperbarui kategori AC: ${errorText}`);
            throw new Error(`Gagal memperbarui kategori AC: ${errorText}`);
        }
        console.log("Kategori AC berhasil diperbarui:", newValue);
        setAcCategory(newValue);
    } catch (error) {
        console.error("Terjadi kesalahan saat memperbarui kategori AC:", error);
    }
};

const fetchData = async (date, period) => {
  try {
    const [year, month] = date.split("-");
    const url = period === "daily" ? `${API_DOMAIN}/monitor/data_daily` : `${API_DOMAIN}/monitor/data_hourly`;
    const response = await fetchWithJwt(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ id: selectedDeviceId, date, month: month.padStart(2, "0"), year }),
    });
    if (!response.ok) throw new Error("Network response was not ok");
    const data = await response.json();
    setSensorData(processData(data));
  } catch (error) {
    setError(`Failed to fetch data: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

  useEffect(() => {
    const fetchAllData = () => {
      fetchDeviceDetail();
      fetchData(currentDate, period);
    };
    fetchAllData();
    const interval = setInterval(fetchAllData, 60000); 
    return () => clearInterval(interval); 
  }, [currentDate, period]); 
  

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-white text-center">
        <div className=" p-8 flex flex-col items-center">
          <img src={logo} alt="Error Illustration" className="w-28 h-28 mb-4 animate-pulse opacity-20"/>
  
          <div className="text-2xl font-semibold text-red-600 mb-2">
            Oops! Something went wrong
          </div>
          <p className="text-gray-600 text-sm">
            {error || "An unexpected error occurred. Please try again."}
          </p>
  
          <button onClick={() => { setError(null); fetchDeviceDetail(); fetchData(currentDate, period); }} className="mt-6 bg-blue-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-600 transition-all duration-300" >
            Refresh Data
          </button>
        </div>
      </div>
    );
  }
  
  
  
  return (
    <div className='bg-white min-h-full overflow-hidden pb-20'>
      <div className="p-4 shadow-md flex items-center gap-2"><MdArrowBack className="cursor-pointer text-2xl text-blue-600" onClick={onClose} />Kembali</div>
      <Card className="p-4 sm:p-4 h-full overflow-y-scroll scrollbar-none">
        <div className="flex flex-col md:text-left">
          {/* Header */}
          <div className="mb-4">
          <div className="flex justify-between items-center sm:text-2xl text-xl font-semibold">
          <div className="flex items-center space-x-2">
            {device.name ? (
              <span>{`AC ${device.name.slice(5, 7)}`}</span>
            ) : (
              <div className="w-20 h-6 bg-gray-300 rounded animate-pulse"></div>
            )}

            <div className="w-8 h-8 flex items-center justify-center text-blue-500 text-blue-700 rounded-full shadow-md">
              {device.status?.some(item => item.code === "battery_percentage") ? (
                <FontAwesomeIcon icon={faBattery} className="w-4 h-4" />
              ) : device.status ? (
                <FontAwesomeIcon icon={faBoltLightning} className="w-4 h-4" />
              ) : (
                <div className="w-4 h-4 bg-gray-300 rounded animate-pulse"></div>
              )}
            </div>
          </div>


            <div className="flex items-center justify-between space-y-4">
            {userRoleId === 1 ? (
              <details className="relative">
                <summary className={`px-4 py-1 rounded-full border text-xs sm:text-sm font-medium cursor-pointer ${
                  acCategory === 1 ? "border-blue-600 text-blue-600 bg-blue-100" :
                  acCategory === 2 ? "border-yellow-500 text-yellow-700 bg-yellow-100" :
                  "border-gray-400 text-gray-400 bg-gray-200"
                }`}>
                  {acCategory === 1 ? "AC - Normal" : acCategory === 2 ? "AC - Birthday" : "Unknown"}
                </summary>
                <div className="absolute mt-1 w-36 bg-white border rounded-md shadow-md">
                  {[{ id: 1, label: "AC - Normal" }, { id: 2, label: "AC - Birthday" }].map(cat => (
                    <div key={cat.id} className="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer" onClick={(e) => { updateAcCategory(cat.id); e.currentTarget.closest("details").removeAttribute("open"); }}>
                      {cat.label}
                    </div>
                  ))}
                </div>
              </details>
            ) : (
              <span className={`px-2 sm:px-6 py-1 rounded-full border text-xs sm:text-sm font-medium ${
                acCategory === 1 ? "border-blue-600 text-blue-600 bg-blue-100" :
                acCategory === 2 ? "border-yellow-500 text-yellow-700 bg-yellow-100" :
                "border-gray-400 text-gray-400 bg-gray-200"
              }`}>
                {acCategory === 1 ? "AC - Normal" : acCategory === 2 ? "AC - Birthday" : "Unknown"}
              </span>
            )}
            </div>
          </div>
          {device.store ? (
            <div className="text-sm sm:text-lg text-gray-700 font-medium">{device.store}</div>
          ) : (
            <div className="w-48 h-6 mt-2 bg-gray-300 rounded animate-pulse"></div>
          )}
          </div>

          {/* Status AC */}
          <div className="grid grid-cols-2 gap-2 sm:gap-3">
            {/* Status Suhu dan Kelembaban */}
            {device.status
              ? ["va_temperature", "va_humidity"].map((statKey, index) => {
                  const stat = device.status.find((s) => s.code === statKey);
                  return (
                    <div
                      key={index}
                      className="flex flex-col items-center bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-md shadow-md"
                    >
                      <span className="text-xs sm:text-base font-medium mb-1">
                        {statKey === "va_temperature" ? "Suhu Ruangan" : "Kelembaban Ruangan"}
                      </span>
                      <span className="flex items-center text-sm sm:text-lg font-bold">
                        {statKey === "va_temperature" ? <MdThermostat /> : <MdWaterDrop />}{" "}
                        {stat ? `${stat.value / (statKey === "va_temperature" ? 10 : 1)}${statKey === "va_temperature" ? "°C" : "%"}` : "-"}
                      </span>
                    </div>
                  );
                })
              : [1, 2].map((_, index) => (
                  <div key={index} className="flex flex-col items-center bg-gray-300 px-4 py-2 rounded-md shadow-md animate-pulse">
                    <div className="w-24 h-4 bg-gray-400 rounded mb-2"></div>
                    <div className="w-16 h-6 bg-gray-400 rounded"></div>
                  </div>
                ))}

            {/* Sensor & IR Remote */}
            {device.online !== undefined && device.remote_online !== undefined ? (
            [{ title: "Sensor Temperatur", online: device.online }, { title: "IR Remote", online: device.remote_online }].map((item, index) => (
              <div key={index} className="flex flex-col items-center bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-md shadow-md">
                <span className="text-xs sm:text-base font-medium">{item.title}</span>
                <span className="text-sm sm:text-lg font-bold">{renderStatusIcon(item.online)}</span>
              </div>
            ))
          ) : (
            [1, 2].map((_, index) => (
              <div key={index} className="flex flex-col items-center bg-gray-300 px-4 py-2 rounded-md shadow-md animate-pulse">
                <div className="w-48 h-4 bg-gray-400 rounded mb-2"></div>
                <div className="w-24 h-4 bg-gray-400 rounded"></div>
              </div>
            ))
          )}

          </div>

          {/* Catatan */}
          <div className="text-sm sm:text-lg space-y-1 mt-4">
          <span className="font-medium">Catatan :</span>
          {device.deskripsi !== undefined ? (
            <p className={`font-medium bg-gray-200 rounded-lg px-3 py-2 min-h-[100px] ${device.deskripsi ? "text-blue-700" : "text-gray-700"}`}>
              {device.deskripsi || "Belum Ada catatan"}
            </p>
          ) : (
            <div className="bg-gray-200 rounded-lg px-3 py-2 min-h-[100px] animate-pulse">
              <div className="w-full h-4 bg-gray-400 rounded mb-2"></div>
              <div className="w-3/4 h-4 bg-gray-400 rounded"></div>
            </div>
          )}
        </div>

          {/* Period Selector & Navigation */}
          <PeriodTabs period={period} setPeriod={setPeriod} />
          <div className="flex items-center justify-between text-sm font-medium gap-4 my-5">
            <button onClick={handlePreviousDay} className="px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md flex items-center">
              <MdArrowCircleLeft className="mr-1" /> Previous
            </button>
            <span className="text-lg font-bold text-gray-700 text-center">
              {period === "hourly"
                ? new Date(currentDate).toLocaleDateString("id-ID", { day: "2-digit", month: "2-digit", year: "numeric" })
                : new Date(currentDate).toLocaleDateString("id-ID", { month: "long", year: "numeric" })}
            </span>
            <button disabled={isToday} onClick={handleNextDay} className={`px-3 py-2 rounded-md flex items-center text-white ${isToday ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"}`}>
              <MdArrowCircleRight className="mr-1" /> Next
            </button>
          </div>


          {/* Chart & Additional Sections */}
          <ChartSection chartData={sensorData} downloadData={downloadData} loading={loading}  />
          {userRoleId === 1 && device.remote && <RemoteSection remote={device.remote} />}
          {userRoleId === 1 && <BroadcastSection broadcastStatus={broadcastStatus} setBroadcastStatus={setBroadcastStatus} deviceId={device.remote} />}
        </div>
      </Card>


      </div>
    );
  };

const PeriodTabs = ({ period, setPeriod }) => (
  <div className="flex items-center justify-center mt-5">
    <PeriodTabButton period={period} setPeriod={setPeriod} target="hourly" label="Day" />
    <PeriodTabButton period={period} setPeriod={setPeriod} target="daily" label="Month" />
  </div>
);

const PeriodTabButton = ({ period, setPeriod, target, label }) => (
  <button onClick={() => setPeriod(target)} className={`text-center w-full py-2 ${period === target ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>{label}</button>
);

const ChartSection = ({ chartData, downloadData }) => {
  const isLoading = !chartData || chartData.length === 0;

  return (
    <div className="">
      <div className={isLoading ? "animate-pulse bg-gray-300 h-40 rounded-md" : ""}>
        {isLoading ? (
          <div className="h-full flex items-center justify-center text-gray-600">Loading Temperature...</div>
        ) : (
          <ChartContainer color="#8884d8" domain={[20, 30]} title="Temperature" dataKey="temperature" chartData={chartData} />
        )}
      </div>

      <div className={isLoading ? "animate-pulse bg-gray-300 h-40 rounded-md mt-4" : ""}>
        {isLoading ? (
          <div className="h-full flex items-center justify-center text-gray-600">Loading Humidity...</div>
        ) : (
          <ChartContainer color="#03b575" domain={[50, 70]} title="Humidity" dataKey="humidity" chartData={chartData} />
        )}
      </div>

      <div className="flex justify-center mt-5">
        <button 
          onClick={downloadData} 
          className="px-4 py-2 bg-green-500 hover:bg-opacity-80 text-white rounded-md flex items-center"
          disabled={isLoading}
        >
          Download Chart
        </button>
      </div>
    </div>
  );
};

const ChartContainer = ({ color, domain, title, dataKey, chartData }) => (
  <div className="">
      <ResponsiveContainer height={200}>
        <AreaChart data={chartData}>
          <Tooltip />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time">
            <Label value={title} offset={10} position="top" />
          </XAxis>
          <Area
            type="monotone"
            dataKey={dataKey}
            stroke={color}
            fill={color}
          />
        </AreaChart>
      </ResponsiveContainer>
  </div>
);


const RemoteSection = ({ remote }) => (
  <div className="shadow-md p-5">
    <Remote deviceId={remote} />
  </div>
);
const renderStatusIcon = (isOnline) => {
  return isOnline 
    ? <span className="text-lime-300 font-bold">Online</span> 
    : <span className="text-red-500 font-bold tracking-wide">Offline</span>;
}; 

export default Detail;
