import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faHome, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Home from '../views/home';
import Setting from '../views/setting';

const MobileLayout = () => {
  const [activeTab, setActiveTab] = useState('Tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = () => {
    Swal.fire({
      text: "Apakah Anda yakin ingin keluar?",
      showCancelButton: true,
      confirmButtonColor: "#007AFF",
      cancelButtonColor: "#FF3B30",
      confirmButtonText: "Ya, keluar",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
        sessionStorage.clear();

        Swal.fire({
          title: "Berhasil Logout!",
          text: "Anda telah keluar.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          navigate("/auth/login");
          window.location.reload();
        });
      }
    });
  };

  const IconButton = ({ icon, label, onClick, isActive }) => (
    <button onClick={onClick} aria-label={label} className="px-2 py-4">
      <div className="flex flex-col items-center">
        <FontAwesomeIcon icon={icon} className={`text-xl ${isActive ? 'text-blue-500' : 'text-gray-500'}`} />
        <span className={`mt-2 text-sm ${isActive ? 'text-blue-500' : 'text-gray-500'}`}>{label}</span>
      </div>
    </button>
  );

  return (
    <div className="h-full flex">
      {/* Sidebar kiri hanya muncul di desktop */}
      <div className="hidden md:flex md:flex-col md:w-[85px] bg-white border-r-2 border-gray-200 fixed left-0 top-0 h-full pt-[125px] justify-between">
        <div className="flex flex-col items-center space-y-6">
          <IconButton icon={faHome} label="Home" onClick={() => handleTabClick('Tab1')} isActive={activeTab === 'Tab1'} />
          <IconButton icon={faGear} label="Setting" onClick={() => handleTabClick('Tab2')} isActive={activeTab === 'Tab2'} />
        </div>
        {/* Logout di bagian bawah dengan warna merah */}
        <div className="pb-6 flex justify-center">
          <button onClick={handleLogout} className="py-2">
            <div className="flex flex-col items-center hover:bg-red-500 hover:text-white text-red-500 hover:rounded-lg p-2">
              <FontAwesomeIcon icon={faRightFromBracket} className="text-xl transform rotate-180" />
              <span className="mt-2 text-sm">Logout</span>
            </div>
          </button>
        </div>
      </div>

      {/* Konten utama */}
      <main className="flex-grow overflow-auto md:ml-[85px]">
        {activeTab === 'Tab1' && <Home />}
        {activeTab === 'Tab2' && <Setting />}
      </main>

      {/* Menu bawah hanya muncul di mobile */}
      <div className="fixed bottom-0 left-0 w-full flex justify-around bg-white shadow-md md:hidden">
        <IconButton icon={faHome} label="Home" onClick={() => handleTabClick('Tab1')} isActive={activeTab === 'Tab1'} />
        <IconButton icon={faGear} label="Setting" onClick={() => handleTabClick('Tab2')} isActive={activeTab === 'Tab2'} />
      </div>
    </div>
  );
};

export default MobileLayout;