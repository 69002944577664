import React from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";

const HeaderWithBackButton = ({ title = "Page Title", children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Fungsi untuk memeriksa jika ada halaman sebelumnya
  const handleBackClick = () => {
    if (location.key === "default") {
      navigate("/"); // Navigasi ke halaman utama jika tidak ada history
    } else {
      navigate(-1); // Kembali ke halaman sebelumnya
    }
  };

  return (
    <>
      <header className="bg-white sticky top-0 left-0 right-0 shadow-md p-4 flex items-center justify-between sm:justify-between flex-wrap z-10">
        {/* Bagian Kiri: Tombol Back dan Judul */}
        <div className="flex items-center">
          <button
            onClick={handleBackClick}
            className="text-blue-600 hover:text-blue-800 transition"
          >
            <MdArrowBack className="h-6 w-6" />
          </button>
          <h1 className="ml-4 text-lg font-semibold text-gray-800">{title}</h1>
        </div>
      </header>
      <div>{children}</div>
    </>
  );
};

export default HeaderWithBackButton;
